import { useEffect, useState } from 'react';
import { redirect } from 'next/navigation';
import { otpFrom } from '@/utils/constants';
import { decrypt } from '@/utils/decrypt';
import { hashPassword } from '@/utils/hashPassword';
import toast from 'react-hot-toast';

import postMessageToParent from '@/lib/postMessage';
import useLoginWithPassword from '@/hooks/useLoginWithPassword';
import useOtpSend from '@/hooks/useOtpSend';
import useOtpVerify from '@/hooks/useOtpVerify';
import useSessionStorageInfo from '@/hooks/useSessionStorageInfo';

import OTPForm from './OTPForm';
import PasswordForm from './PasswordForm';

const PasswordAndOtpForm = ({
  email,
  setIsEmailForm,
  signUpWith,
  tenantId,
  isPasswordEnabled,
  isOtpEnable,
  shakey,
}: {
  email: string;
  setIsEmailForm: (isEmailForm: boolean) => void;
  signUpWith: string;
  isOtpEnable: boolean;
  tenantId: string;
  isPasswordEnabled: boolean | undefined;
  shakey: string;
}) => {
  const { redirectURL, postParent } = useSessionStorageInfo();
  const [password, setPassword] = useState('');
  const [isPasswordSelected, setIsPasswordSelected] = useState(false);
  const { tenantData, clientId } = useSessionStorageInfo();
  const [otp, setOtp] = useState('');
  const [passwordErroMsg, setPasswordErrorMsg] = useState('');
  const [otpErroMsg, setOtpErrorMsg] = useState('');

  const {
    loading: passwordLoading,
    data: passwordData,
    handleClick: handleLoginWithPassword,
    error: passwordError,
  } = useLoginWithPassword();

  const {
    loading: otpSendLoading,
    data: sendOtpResponse,
    handleClick: sendOtp,
    error: otpSendError,
  } = useOtpSend();

  const {
    loading: otpLoading,
    data: otpVerifyResponse,
    handleClick: handleOtpVerify,
    error: otpVerifyError,
  } = useOtpVerify();

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newPassword = decrypt(shakey) + password;

    const hashPass = hashPassword(newPassword);

    handleLoginWithPassword(email, tenantId, hashPass);
  };

  const handleVerify = () => {
    handleOtpVerify(email, tenantId, otp);
  };

  useEffect(() => {
    const response: any = passwordData;
    if (response && response.accessToken) {
      toast.success('Successfully logged in.');
      setOtpErrorMsg('');
      setPasswordErrorMsg('');
      if (postParent) {
        postMessageToParent({ ...response, client_id: clientId });
        redirect('/successful-login');
      }
      if (redirectURL) {
        window.location.replace(
          `${redirectURL}?accessToken=${response.accessToken}&refreshToken=${response.refreshToken}&tenantId=${tenantData?._id}&tenantName=${tenantData?.tenantName}&clientId=${clientId}`
        );
      }
    }
  }, [passwordData]);

  useEffect(() => {
    if (otpVerifyResponse) {
      if (otpVerifyResponse.success) {
        if (postParent) {
          postMessageToParent({ ...otpVerifyResponse, client_id: clientId });
          redirect('/successful-login');
        }

        if (redirectURL) {
          window.location.replace(
            `${redirectURL}?accessToken=${otpVerifyResponse.accessToken}&refreshToken=${otpVerifyResponse.refreshToken}&tenantId=${tenantData?._id}&tenantName=${tenantData?.tenantName}&clientId=${clientId}`
          );
        }
      } else {
        toast.error(otpVerifyResponse.message);
        setOtpErrorMsg(otpVerifyResponse.message);
      }
    }
  }, [otpVerifyResponse]);

  useEffect(() => {
    if (passwordError) {
      toast.error(passwordError);
      setPasswordErrorMsg(passwordError);
    }

    if (otpVerifyError) {
      toast.error(otpVerifyError);
      setOtpErrorMsg(otpVerifyError);
    }
  }, [passwordError, otpVerifyError]);

  useEffect(() => {
    if (isPasswordEnabled === true && signUpWith === 'linkedin') {
      setIsPasswordSelected(true);
    } else if (isPasswordEnabled === true) {
      setIsPasswordSelected(true);
    } else if (signUpWith === 'otp') {
      setIsPasswordSelected(false);
    }
  }, [signUpWith]);

  useEffect(() => {
    if (sendOtpResponse) {
      setIsPasswordSelected(false);
    }
  }, [sendOtpResponse]);

  useEffect(() => {
    if (otpSendError) {
      const errorText = 'Error while sending OTP';
      toast.error(errorText);
      setOtpErrorMsg(errorText);
      setIsPasswordSelected(true);
    }
  }, [otpSendError]);

  return (
    <div>
      {isPasswordEnabled && isOtpEnable && (
        <div>
          <p className="mb-2 text-sm text-zinc-500">Select your login method</p>
          <div className="grid h-[36px] grid-cols-2 items-center justify-center gap-x-3 bg-zinc-100 px-1 text-sm">
            <div
              onClick={() => setIsPasswordSelected(true)}
              className={`flex h-[28px] cursor-pointer items-center justify-center ${isPasswordSelected ? 'bg-white font-medium' : 'bg-transparent'}`}
            >
              Password
            </div>
            <div
              onClick={() => {
                if (isPasswordSelected) {
                  sendOtp(email, tenantId, otpFrom.SIGNIN);
                }
              }}
              className={`flex h-[28px] ${otpSendLoading ? 'cursor-wait' : 'cursor-pointer'} items-center justify-center ${!isPasswordSelected ? 'bg-white font-medium' : 'bg-transparent'}`}
            >
              {otpSendLoading ? 'OTP Sending...' : 'OTP'}
            </div>
          </div>
        </div>
      )}
      {isPasswordSelected ? (
        <PasswordForm
          email={email}
          loading={passwordLoading}
          password={password}
          setPassword={setPassword}
          setIsEmailForm={setIsEmailForm}
          handleLogin={handleLogin}
          tenantId={tenantId}
          errorMsg={passwordErroMsg}
        />
      ) : (
        <OTPForm
          loading={otpLoading}
          email={email}
          value={otp}
          setValue={setOtp}
          handleVerify={handleVerify}
          setIsEmailForm={setIsEmailForm}
          tenantId={tenantId}
          errorMsg={otpErroMsg}
          setErrorMsg={setOtpErrorMsg}
        />
      )}
    </div>
  );
};

export default PasswordAndOtpForm;
