import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import toast from 'react-hot-toast';

import useForgotPassword from '@/hooks/useForgotPassword';
import useSessionStorageInfo from '@/hooks/useSessionStorageInfo';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import InputErrorMsg from '@/components/reUsables/InputErrorMsg';

const PasswordForm = ({
  email,
  loading,
  setIsEmailForm,
  password,
  setPassword,
  handleLogin,
  tenantId,
  errorMsg,
}: {
  email: string;
  loading: boolean;
  password: string;
  setPassword: (password: string) => void;
  setIsEmailForm: (isEmailForm: boolean) => void;
  handleLogin: (e: React.FormEvent<HTMLFormElement>) => void;
  tenantId: string;
  errorMsg?: string;
}) => {
  const router = useRouter();
  const { clientId, redirectURL, postParent } = useSessionStorageInfo();

  const {
    loading: forgotPasswordLoading,
    data,
    handleClick,
    error,
  } = useForgotPassword();

  const handleForgotPassword = () => {
    handleClick(email, tenantId, clientId, redirectURL, postParent);
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (data?.success) {
      toast.success(data.message);
      router.push(`/forgot-password?email=${email}&tenantId=${tenantId}`);
    }
  }, [data]);

  return (
    <div>
      <div className="mt-8">
        <form onSubmit={handleLogin}>
          <Label htmlFor="password">Password</Label>
          <Input
            className={`mt-2 ${errorMsg && 'border-red-500'}`}
            type="password"
            id="password"
            required
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoFocus
          />
          {errorMsg && (
            <div className="my-1">
              <InputErrorMsg text={errorMsg} />
            </div>
          )}
          <p
            onClick={
              forgotPasswordLoading
                ? () => console.log('Loading...')
                : handleForgotPassword
            }
            className={`mt-4 text-sm font-medium ${forgotPasswordLoading ? 'cursor-wait text-zinc-400' : 'cursor-pointer text-primary-950'} `}
          >
            {forgotPasswordLoading ? 'Sending...' : 'Forgot Password?'}
          </p>
          <br />
          <Button disabled={loading} type="submit" size="fullWidth">
            {loading ? 'Loading...' : 'Login'}
          </Button>
        </form>
      </div>
      <p
        onClick={() => setIsEmailForm(true)}
        className="cursor-pointer pt-6 text-center text-sm font-medium text-primary-950 hover:underline"
      >
        Login with another account
      </p>
    </div>
  );
};

export default PasswordForm;
