'use client';

import { useSearchParams } from 'next/navigation';
import { tenant } from '@/interfaces/common';
import { setCookie } from 'cookies-next';

import useMinifyChecker from '@/hooks/useMinifyChecker';

import LeftSidebar from './sections/LeftSidebar';
import LoginForm from './sections/LoginForm';

export default function LoginPage({
  tenant,
  clientId,
}: {
  tenant: tenant;
  clientId: string;
}) {
  if (
    typeof window !== 'undefined' &&
    tenant &&
    Object.keys(tenant)?.length > 0 &&
    tenant !== undefined
  ) {
    window.sessionStorage.setItem('tenant', JSON.stringify(tenant));
    setCookie('tenant', JSON.stringify(tenant));
  }

  const searchParams = useSearchParams();
  const minifyParams = searchParams.get('minify') as string | undefined;
  const postParent = searchParams.get('postParent') as string | undefined;
  const redirectURLParams = searchParams.get('redirectURL') as
    | string
    | undefined;

  if (
    typeof window !== 'undefined' &&
    redirectURLParams &&
    redirectURLParams !== undefined
  ) {
    window.sessionStorage.setItem('redirectURL', redirectURLParams);
  }

  if (typeof window !== 'undefined' && postParent && postParent !== undefined) {
    window.sessionStorage.setItem('postParent', 'true');
  }

  const { setMinifyInSessionStorage } = useMinifyChecker();
  setMinifyInSessionStorage(minifyParams);

  const { minify } = useMinifyChecker();

  return (
    <div className="h-screen bg-zinc-100 p-[24px]">
      <div
        className={`rounded-3xl bg-white ${minify ? 'grid-cols-1' : 'grid-cols-2'} grid`}
      >
        <LoginForm tenant={tenant} clientId={clientId} />
        {!minify && <LeftSidebar />}
      </div>
    </div>
  );
}
