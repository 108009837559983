import { useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';

const useLoginWithPassword = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);

  const clientId =
    typeof window !== 'undefined'
      ? window.sessionStorage.getItem('clientId')
      : null;

  const handleClick = async (
    email: string,
    tenantId: string,
    password: string
  ) => {
    if (email && tenantId && password && clientId) {
      setLoading(true);
      setError('');
      try {
        const url = process.env.NEXT_PUBLIC_API_URL;
        const res = await axios.post(`${url}/login`, {
          user_id: email,
          password: password,
          tenant_id: tenantId,
          client_id: clientId,
        });

        if (res?.data?.error === 1) {
          setError(res?.data?.message);
        } else if (res?.data?.error === -1) {
          setData(res?.data?.data);
        }
      } catch (err: any) {
        if (err?.response) {
          setError(err?.response?.data?.message);
        } else {
          setError('An error occurred. Please try again later.');
        }
      } finally {
        setLoading(false);
      }
    } else {
      toast.error('Email, Tenant id, client id and password must be specified');
    }
  };

  return {
    loading,
    handleClick,
    data,
    error,
  };
};

export default useLoginWithPassword;
