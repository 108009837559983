import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const truncateEmail = (email: string) => {
  if (email) {
    const parts = email.split('@');
    if (parts.length === 2) {
      const truncatedName = parts[0].slice(0, 3) + '...';
      return truncatedName + '@' + parts[1];
    } else {
      return email;
    }
  }
};
