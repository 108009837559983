import { useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';

const useForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>('');
  const [error, setError] = useState<string>('');

  const handleClick = async (
    email: string,
    tenantId: string,
    clientId: string,
    redirectURL: string,
    postParent: boolean
  ) => {
    if (email && tenantId && clientId && (redirectURL || postParent)) {
      setError('');
      setLoading(true);
      try {
        const url = process.env.NEXT_PUBLIC_API_URL;
        const res = await axios.post(`${url}/forgot-password`, {
          email,
          tenantId,
          clientId,
          redirectURL,
          postParent,
        });
        if (res.data) {
          setData(res.data);
        }
      } catch (error: any) {
        const axiosError = error;
        if (axiosError.response) {
          setError(axiosError?.response?.data?.message || 'An error occurred');
        } else if (axiosError.request) {
          setError('Request failed, please try again later');
        } else {
          setError('An error occurred, please try again later');
        }
      } finally {
        setLoading(false);
      }
    } else {
      toast.error('Email and tenant id is required');
    }
  };

  return {
    loading,
    handleClick,
    data,
    error,
  };
};

export default useForgotPassword;
