'use client';

import { useEffect, useState } from 'react';
import Image from 'next/image';
import { allowedAuth, tenant } from '@/interfaces/common';
import { otpFrom } from '@/utils/constants';
import toast from 'react-hot-toast';

import useEmailCheck from '@/hooks/useEmailCheck';
import useOtpSend from '@/hooks/useOtpSend';

import EmailForm from './EmailForm';
import PasswordAndOtpForm from './PasswordAndOtpForm';

const LoginForm = ({
  tenant,
  clientId,
}: {
  clientId: string;
  tenant: tenant;
}) => {
  // setting the tenantId in the session storage for the linkedin login.
  if (tenant?._id !== undefined && typeof window !== 'undefined') {
    window.sessionStorage.setItem('tenantId', tenant?._id);
    if (clientId !== undefined && clientId !== '' && clientId !== null) {
      window.sessionStorage.setItem('clientId', clientId);
    }
  }

  const [isEmailForm, setIsEmailForm] = useState(true);
  const [email, setEmail] = useState('');
  const [signUpWith, setSignUpWith] = useState('');
  const [allowedAuth, setAllowedAuth] = useState<allowedAuth>({
    linkedin: false,
    otp: true,
    password: false,
    sso: false,
  });
  const [errorMsg, setErrorMsg] = useState('');

  const REQUEST_FROM_SIGNUP = false;
  const {
    loading: emailCheckLoading,
    data,
    handleClick: checkEmail,
    error: emailCheckError,
  } = useEmailCheck({ REQUEST_FROM_SIGNUP });

  const {
    loading: otpSendLoading,
    data: otpResponse,
    handleClick: sendOtp,
    error: otpSendError,
  } = useOtpSend();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSignUpWith('');
    if (isEmailForm) {
      checkEmail(email, tenant?._id);
    }
  };

  useEffect(() => {
    if (tenant) {
      setAllowedAuth({
        otp: tenant?.otp,
        linkedin: tenant?.linkedin,
        password: tenant?.passwordBasedAuth,
        sso: tenant?.singleSignOnChecked,
      });
    } else {
      setAllowedAuth({
        linkedin: false,
        otp: true,
        password: false,
        sso: false,
      });
    }
  }, [tenant]);

  useEffect(() => {
    if (emailCheckError) {
      toast.error(emailCheckError);
      setErrorMsg(emailCheckError);
    }
    if (otpSendError) {
      toast.error(otpSendError);
    }
  }, [emailCheckError, otpSendError]);

  useEffect(() => {
    if (data && data.email) {
      if (data?.isUserInSameTenant) {
        setSignUpWith(data.signUpWith);

        if (data.isPasswordEnabled === true) {
          setIsEmailForm(false);
          setErrorMsg('');
        }
      } else {
        const errorText =
          'You are not signed up with this tenant, please signup first with this tenant';
        toast.error(errorText);
        setErrorMsg(errorText);
      }
    }
  }, [data]);

  useEffect(() => {
    if (!data?.isPasswordEnabled && signUpWith === 'otp') {
      sendOtp(email, tenant?._id, otpFrom.SIGNIN);
    }
  }, [signUpWith, data]);

  useEffect(() => {
    if (otpResponse) {
      setErrorMsg('');
      toast.success('Otp sent to your mail.');
      setIsEmailForm(false);
    }
  }, [otpResponse]);

  return (
    <div className="flex h-[calc(100vh-48px)] items-center justify-center overflow-y-auto rounded-3xl bg-white">
      <div className="w-[384px]">
        {tenant?.logo && (
          <Image
            src={tenant?.logo}
            alt={tenant?.tenantName}
            width={120}
            height={53}
          />
        )}
        <h1
          className={`text-3xl font-bold ${isEmailForm ? 'my-12 mb-8' : 'mb-1 mt-12'}`}
        >
          {isEmailForm ? 'Hello! Ready to Explore?' : 'Great to See You Again!'}
        </h1>
        {!isEmailForm && (
          <p className="mb-5 text-base font-semibold text-zinc-500">{email}</p>
        )}
        {isEmailForm && (
          <EmailForm
            email={email}
            setEmail={setEmail}
            handleSubmit={handleSubmit}
            loading={emailCheckLoading || otpSendLoading}
            allowedAuth={allowedAuth}
            tenantId={tenant?._id}
            errorMsg={errorMsg}
          />
        )}
        {!isEmailForm && (
          <PasswordAndOtpForm
            email={email}
            signUpWith={signUpWith}
            isPasswordEnabled={data?.isPasswordEnabled}
            setIsEmailForm={setIsEmailForm}
            tenantId={tenant?._id}
            isOtpEnable={data?.isOtpEnable ?? true}
            shakey={data?.shakey ?? ''}
          />
        )}
      </div>
    </div>
  );
};

export default LoginForm;
